
import React from 'react';
import './eligibility.scss'


export default function Eligibility(props) {


    return (
        <section id="eligibility" className='text-center visible pad-top-50 pad-bottom-50'>
            <div className="container">
                <div className="grid-container">
                    <div className="grid-x align-center align-middle height60">
                        <div className="small-12 cell">
                            <h1 className="h1">Application Requirements</h1>
                        </div>

                        {/* <div className="small-10 cell">
                            <p>We encourage anyone eligible to apply and help build a more inclusive and diverse community in tech.</p>
                        </div> */}
                        <div className="small-12 medium-6 pad-20">
                            <div className="card card__inner">
                                <div className="card__body">
                                    {/* <div className="small-12 cell">
                                        <h2>Eligible applicants must be:</h2>
                                    </div> */}

                                    <div className="small-8 cell">
                                        <p>Eligible applicants must be residents of the United States, Australia, South Africa, or New Zealand and graduates of or currently enrolled in a nonprofit workforce development program committed to skilling up Salesforce talent from underserved communities or groups underrepresented in technology. Must have achieved at least Mountaineer rank on Trailhead (currently equivalent to 25 badges and 18,000 points).</p>
                                    </div>
                                    {/* <div className="small-8 cell">
                                        <h2>AND</h2>
                                    </div>
                                    <div className="small-8 cell">
                                        <p>Successfully complete the <a href="https://trailhead.salesforce.com/users/strailhead/trailmixes/be-a-trailblazer" target="_blank">Be a Trailblazer Trailmix</a> and a below Trailmix associated with your certification preparation (CRT) class selection:</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="small-12 medium-6 pad-20 pb-40">
                            <div className="card card__inner">
                                <div className="card__body">
                                    <div className="small-8 cell pl-10">
                                        {/* <p>Complete the <a href="https://trailhead.salesforce.com/users/strailhead/trailmixes/be-a-trailblazer">Be A Trailblazer trailmix</a></p> */}
                                        {/* <h2>AND</h2> */}
                                        {/* <p>Complete the Trailmix associated with your course</p> */}
                                        <p>Eligible applicants are also required to complete the <a href="https://trailhead.salesforce.com/users/strailhead/trailmixes/be-a-trailblazer" target="_blank">Be a Trailblazer Trailmix</a> and a below Trailmix associated with your certification preparation (CRT) class selection:</p>
                                        <ul className="text-left">
                                            {/* <li><a href="https://trailhead.salesforce.com/users/strailhead/trailmixes/be-a-trailblazer" target="_blank">Be a Trailblazer Trailmix</a></li> */}
                                            <li><a href="https://trailhead.salesforce.com/en/content/learn/trails/administrator-certification-prep" target="_blank">Study for the Administrator Certification</a></li>
                                            <li><a href="https://trailhead.salesforce.com/en/content/learn/trails/force_com_admin_advanced" target="_blank">Prepare for Your Salesforce Advanced Administrator Credential</a></li>
                                            <li><a href="https://trailhead.salesforce.com/en/content/learn/trails/platform-app-builder-certification-prep" target="_blank">Study for Your Salesforce Platform App Builder Credential</a></li>
                                            <li><a href="https://trailhead.salesforce.com/en/content/learn/trails/platform-developer-i-certification-study-guide" target="_blank">Study for Your Salesforce Platform Developer I Credential</a></li>
                                            <li><a href="https://trailhead.salesforce.com/content/learn/trails/study-for-the-marketing-cloud-administrator-exam" target="_blank">Study for the Marketing Cloud Administrator Exam</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section >
    )
};
