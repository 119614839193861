
import React from 'react';
import './offerings.scss'


export default function Offerings(props) {


    return (
        <section id="offerings" className='text-center visible pad-top-50'>
            <div className="container">
                <div className="grid-container">
                    <div className="grid-x align-center align-middle height60">
                        <div className="small-12 cell">
                            <div className="grid-x align-center">
                                <div className="small-12 medium-10 large-6 cell">
                                    <h1 className="h1 mb-20">Course Offerings</h1>
                                    {/* <h2>Trailhead Scholarship Courses</h2> */}
                                </div>
                            </div>
                        </div>

                        <div className="small-12 cell">
                            <p>Scholarship recipients may select one of the following Certification Preparation (CRT) courses:</p>
                        </div>
                        <div className="grid-x grid-padding-x grid-padding-y align-center justify-content-center">
                            <div className="small-12 medium-6 large-4 cell">
                                {/* <a className="no-underline" href="https://trailhead.salesforce.com/en/content/learn/trails/administrator-certification-prep" target="_blank"> */}
                                    <div className="card card-hoverable card__inner">
                                        <div className="card__body">
                                            Cert Prep for Admin<br /><br />(CRT101)
                                        </div>
                                            <div className="card__links">
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=a38512c9-1da2-11ed-9af1-0cc47a34e6a4">PST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=0c183487-1da3-11ed-9af1-0cc47a34e6a4">EST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=b896e33c-1da3-11ed-9af1-0cc47a34e6a4">SAST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=53d50fb9-1da4-11ed-9af1-0cc47a34e6a4">NZDT</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=61c6dace-1da6-11ed-9af1-0cc47a34e6a4">AEDT</a></span>
                                            </div>
                                    </div>
                                {/* </a> */}
                            </div>
                            <div className="small-12 medium-6 large-4 cell">
                                {/* <a className="no-underline" href="https://trailhead.salesforce.com/en/users/strailhead/trailmixes/prepare-for-your-salesforce-advanced-administrator-credential" target="_blank"> */}
                                    <div className="card card-hoverable card__inner">
                                        <div className="card__body">
                                            Cert Prep for Advanced Admin<br /><br />(CRT211)
                                            <div className="card__links">
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=729d9075-1da8-11ed-9af1-0cc47a34e6a4">PST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=e8631ae5-1dab-11ed-9af1-0cc47a34e6a4">EST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=32dbb322-1dac-11ed-9af1-0cc47a34e6a4">SAST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=d938ac9c-1dac-11ed-9af1-0cc47a34e6a4">NZDT</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=51ab07b7-1dad-11ed-9af1-0cc47a34e6a4">AEDT</a></span>
                                            </div>
                                        </div>
                                    </div>
                                {/* </a> */}
                            </div>
                            <div className="small-12 medium-6 large-4 cell">
                                {/* <a className="no-underline" href="https://trailhead.salesforce.com/en/content/learn/trails/platform-app-builder-certification-prep" target="_blank"> */}
                                    <div className="card card-hoverable card__inner">
                                        <div className="card__body">
                                            Cert Prep for Platform App Builder<br /><br />(CRT403)
                                            <div className="card__links">
                                            <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=535400f3-1db0-11ed-9af1-0cc47a34e6a4">PST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=8544998b-1db5-11ed-9af1-0cc47a34e6a4">EST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=d667ddce-1db5-11ed-9af1-0cc47a34e6a4">SAST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=591de454-1db6-11ed-9af1-0cc47a34e6a4">NZDT</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=f556972e-1db6-11ed-9af1-0cc47a34e6a4">AEDT</a></span>
                                            </div>
                                        </div>
                                    </div>
                                {/* </a> */}
                            </div>
                            <div className="small-12 medium-6 large-4 cell">
                                {/* <a className="no-underline" href="https://trailhead.salesforce.com/en/content/learn/trails/platform-developer-i-certification-study-guide */}
                                {/* " target="_blank"> */}
                                    <div className="card card-hoverable card__inner">
                                        <div className="card__body">
                                            Cert Prep for Platform Developer I<br /><br />(CRT450)
                                            <div className="card__links">
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=612fc7ac-1db7-11ed-9af1-0cc47a34e6a4">PST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=c8f4248e-1db7-11ed-9af1-0cc47a34e6a4">EST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=19d2ee30-1db8-11ed-9af1-0cc47a34e6a4">SAST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=a13faeaa-1db8-11ed-9af1-0cc47a34e6a4">NZDT</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=4ae2499c-1db9-11ed-9af1-0cc47a34e6a4">AEDT</a></span>
                                            </div>
                                        </div>
                                    </div>
                                {/* </a> */}
                            </div>
                            <div className="small-12 medium-6 large-4 cell">
                                {/* <a className="no-underline" href="https://trailhead.salesforce.com/en/content/learn/trails/platform-developer-i-certification-study-guide */}
                                {/* " target="_blank"> */}
                                    <div className="card card-hoverable card__inner">
                                        <div className="card__body">
                                            Cert Prep for Marketing Cloud Administrator<br /><br />(CRT250)
                                            <div className="card__links">
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=d601d5a8-1db9-11ed-9af1-0cc47a34e6a4">PST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=a7dc8849-1dbb-11ed-9af1-0cc47a34e6a4">EST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=ffd02814-1dbb-11ed-9af1-0cc47a34e6a4">SAST</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=86384381-1dbc-11ed-9af1-0cc47a34e6a4">NZDT</a></span>
                                                <span>|</span>
                                                <span><a target="_blank" href="https://trailheadacademy.salesforce.com/checkout?type=EVENT&id=efdc38a1-1dbc-11ed-9af1-0cc47a34e6a4">AEDT</a></span>
                                            </div>
                                        </div>
                                    </div>
                                {/* </a> */}
                            </div>
                            
                            <p className="footnote pad-top-20 pad-bottom-50"><em>*Classes will run the week of January 30 - February 3, 2023</em></p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
};
