import React,
{
  useState,
} from 'react';
import {
  Link,
  animateScroll
    as scroll
} from "react-scroll";
import Hero from './hero/hero';
import Offerings from './offerings/offerings';
import Description from './description/description';
import Eligibility from './eligibility/eligibility';
import Testimonial from './testimonial/testimonial';
import Awards from './awards/awards';
import Cta from './cta/cta';
import Why from './why/why';
import Nav from './nav';

export default function MainPage(props) {
  let nav = <Nav
    contactModalOpen={props.contactModalOpen}
    setContactModalOpen={props.setContactModalOpen}
    closeContactModal={props.closeContactModal}
  />;
  let hero = <Hero
    contactModalOpen={props.contactModalOpen}
    setContactModalOpen={props.setContactModalOpen}
    closeContactModal={props.closeContactModal}
  />;
  let description = <Description
    contactModalOpen={props.contactModalOpen}
    setContactModalOpen={props.setContactModalOpen}
    closeContactModal={props.closeContactModal}
  />;
  let why = <Why/>
  let eligibility = <Eligibility />;
  let cta = <Cta
    contactModalOpen={props.contactModalOpen}
    setContactModalOpen={props.setContactModalOpen}
    closeContactModal={props.closeContactModal}
  />;
  let offerings = <Offerings 
  contactModalOpen={props.contactModalOpen}
  setContactModalOpen={props.setContactModalOpen}
  closeContactModal={props.closeContactModal}
  />;
  let awards = <Awards 
  contactModalOpen={props.contactModalOpen}
  setContactModalOpen={props.setContactModalOpen}
  closeContactModal={props.closeContactModal}  
  />
  let testimonial = <Testimonial />;

  // RENDER ///////////////////////////////////
  return (
    <div className="main-page">
      {nav}
      {hero}
      {description}
      {awards}
      {eligibility}
      {why}
      {offerings}
      {cta}
      {testimonial}
    </div>
  );
}