
import React from 'react';
import './cta.scss'


export default function Cta(props) {

    return (
        <section id="cta" className='text-center visible pt-20 pb-40'>
            <div className="container">
                <div className="grid-container">
                    <div className="grid-x align-center align-middle">
                        <div className="small-12 cell">
                                {/* <a onClick={() => props.setContactModalOpen(true)} id={props.id} target="_blank" type="button" rel="noopener noreferrer" className="button large btn-blue">Apply Now</a> */}
                                {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSdVQ4W3C02Dvcr3UE1uU_2c6tJPcmVncgE8pJNAfExQkU-o3Q/viewform?usp=sf_link" target="_blank" type="button" rel="noopener noreferrer" className="button large btn-blue">Apply Now</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
};
