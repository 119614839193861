import React from 'react';
import './awards.scss'

export default function Awards(props) {


    return (
        <section id="awards" className='text-center visible'>
            <div className="container" >
                <div className="grid-container">
                    <div className="grid-x align-center align-middle height60 pad-bottom-50">
                        <div className="small-12 cell">
                            <div className="grid-x align-center">
                                <div className="small-12 medium-10 large-7 cell">
                                    {/* <h1 className="h1 mb-20">Reap The Rewards</h1> */}
                                    <h2>Scholarship recipients will receive:</h2>
                                </div>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x grid-padding-y align-center">
                            <div className="small-12 medium-4 large-4 cell">
                                <div className="card card__inner">
                                    <img className="card__img" src="images/awards/astro.png" />
                                    <div className="card__body 4line">
                                      A seat in their choice of one of the certification preparation (CRT) classes below
                                    </div>
                                </div>
                            </div>
                            <div className="small-12 medium-4 large-4 cell">
                                <div className="card card__inner">
                                    <img className="card__img" src="images/awards/astrocert.png" />
                                    <div className="card__body 4line">
                                      A $200 USD Salesforce certification exam voucher for one of the courses listed below
                                    </div>
                                </div>
                            </div>
                            <div className="small-12 medium-4 large-4 cell">
                                <div className="card card__inner">
                                    <img className="card__img" src="images/awards/guide.png" />
                                    <div className="card__body 4line">
                                      A 1-hour one-on-one coaching session with a Trailblazer Guide focused on career advice, Trailhead tips and tricks, Trailblazer Community
                                    </div>
                                </div>
                            </div>
                            <div className="small-12 medium-4 large-4 cell">
                                <div className="card card__inner">
                                    <img className="card__img" src="images/awards/resumeastro.png" />
                                    <div className="card__body 4line">
                                        1-hour coaching session on resume-building and interview skills with the Salesforce Recruiting team
                                    </div>
                                </div>
                            </div>
                            <div className="small-12 medium-4 large-4 cell">
                                <div className="card card__inner">
                                    <img className="card__img" src="images/awards/astroparty.png" />
                                    <div className="card__body 4line">
                                        A Trailblazer Scholar virtual celebration with resources to empower you to engage with Trailhead, the Trailblazer Community, and Salesforce+
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-x align-center align-middle">
                        <div className="small-12 mb-40 cell">
                            {/* <a onClick={() => props.setContactModalOpen(true)} id={props.id} target="_blank" type="button" rel="noopener noreferrer" className="button large btn-blue">Apply Now</a> */}
                            {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSdVQ4W3C02Dvcr3UE1uU_2c6tJPcmVncgE8pJNAfExQkU-o3Q/viewform?usp=sf_link" target="_blank" type="button" rel="noopener noreferrer" className="button large btn-blue">Apply Now</a> */}
                        </div>
                    </div>
                </div>
            </div>

        </section >
    )
};
