import React, { useEffect } from 'react';
import Equalizer from 'react-equalizer';
import Nav from '../nav.js';
import './hero.scss'
import ReactGA from 'react-ga';
import Footer from '../footer/footer.js';
import uuid from 'react-uuid';
import CtaButtons from '../cta_buttons';
import CtaFormModals from '../cta_form_modals';
import { useSpring, animated } from 'react-spring';
import butterfly1, { ReactComponent as Butterfly1 } from './images/butterfly1.svg';
import butterfly2, { ReactComponent as Butterfly2 } from './images/butterfly2.svg';
import bird4, { ReactComponent as Bird } from './images/bird5.svg';
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 30 * -1}px,${y / 30}px,0)`;


export default function Hero(props) {
  const [settings, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 50, tension: 550, friction: 140 } }))
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
    };
  }, []);

  ReactGA.event({
    category: 'Resources',
    action: 'page visited'
  });
  return (
    <>
      {/* <Nav
        contactModalOpen={props.contactModalOpen}
        setContactModalOpen={props.setContactModalOpen}
        closeContactModal={props.closeContactModal}
      /> */}
      <section id="hero" className='text-center visible'>
        <div className="grid-container relative">
          <div className="grid-x align-center full-height ">
            <div className="hero-heading small-12 medium-12 large-12 cell">

              <h2 className="intro-title">Blaze New Trails in Your Career</h2>
              <h1 className="main-title"><span className="blue-light script">2023 Trailblazer Scholarship</span></h1>
              {/* <p className="main-sub-title ">Applications open September 13 - November 30, 2022* </p> */}
              <p className="main-sub-title ">
                The Trailblazer Scholarship Program is not accepting applications for 2024. 
                <br/>
                To kick start your Salesforce learning journey, sign up for <a href="https://trailhead.salesforce.com/">Trailhead</a> and check out the <a href="https://trailhead.salesforce.com/en/trailblazerconnect/directory/">Trailblazer Workforce Partner</a> directory to find global Salesforce training programs.
              </p>
              <div className="grid-sm grid-x grid-padding-x grid-padding-y align-center">
            {/* <div className="small-12 medium-3 large-3 cell">
              <a target="_blank" href="https://docs.google.com/forms/d/1t6XOvl_ItWyMpMROqfnIhGipLXTu9oBHEH3QNlxzxhc/edit" className="button sm btn-blue">Australia</a>
            </div>
            <div className="small-12 medium-3 large-3 cell">
              <a target="_blank" href="https://docs.google.com/forms/d/1QhtI4DGob_t1GUp0aJibuHmA-6pL8PZwttWHEx_mIic/edit" className="button sm btn-blue">New Zealand</a>
            </div>
            <div className="small-12 medium-3 large-3 cell">
              <a target="_blank" href="https://docs.google.com/forms/d/1DEwOjCU5rJdYwN_mVzRLqsyHHup0Q8Ab_l-995f8FhA/edit" className="button sm btn-blue">South Africa</a>
            </div>
            <div className="small-12 medium-3 large-3 cell">
              <a target="_blank" href="https://docs.google.com/forms/d/1VXHQT3nj5M4mf9GdJWXL1Zg_Vqs9D8q6PZye69T1B4A/edit"  className="button sm btn-blue">United States</a>
            </div> */}
          </div>
              {/* <div className="btn-wrap  pb-100"> */}
              {/* <a onClick={() => props.setContactModalOpen(true)} id={props.id} target="_blank" type="button" rel="noopener noreferrer" className="button large btn-blue">Apply Today</a> */}
              {/* <a  href="https://docs.google.com/forms/d/e/1FAIpQLSdVQ4W3C02Dvcr3UE1uU_2c6tJPcmVncgE8pJNAfExQkU-o3Q/viewform?usp=sf_link" target="_blank" type="button" rel="noopener noreferrer" className="button large btn-blue">Apply Today</a> */}
              {/* <div className="small-12 cell"> */}
              {/* <p className="footnote  pb-30">For more details about eligibility, check out <a href="https://docs.google.com/document/d/1rvQCyq5KDq0Zy88VaNRP0G7PhtaXl4Fb-MA93XgUf2g/edit" target="_blank">the official rules.</a></p> */}
              {/* </div> */}
              {/* <h4 className="text-center">Registration Closed</h4> */}


              {/* <div className="country-grid grid-sm grid-x grid-padding-x grid-padding-y align-center">
                  <div className="small-12 medium-6 large-6 cell">
                    <a className="no-underline" href="#" target="_blank">
                      <div className="card card-country card-united-states card-hoverable card__inner">
                      </div>
                    </a>
                  </div>
                  <div className="small-12 medium-6 large-6 cell">
                    <a className="no-underline" href="#" target="_blank">
                      <div className="card  card-country card-australia card-hoverable card__inner">
                      </div>
                    </a>
                  </div>
                  <div className="small-12 medium-6 large-6 cell">
                    <a className="no-underline" href="#" target="_blank">
                      <div className="card  card-country card-new-zealand card-hoverable card__inner">
                      </div>
                    </a>
                  </div>
                  <div className="small-12 medium-6 large-6 cell">
                    <a className="no-underline" href="#" target="_blank">
                      <div className="card  card-country card-south-africa card-hoverable card__inner">
                      </div>
                    </a>
                  </div>
                </div> */}
              {/* </div> */}
            </div>
          </div>

        </div>

      </section>

    </>
  )
};
