import React, {useEffect} from 'react';
import Modal from 'react-modal';

export default function CtaFormModals(props){
  useEffect(() => {
    Modal.setAppElement('#app');

  }, []);
  return(
    <Modal
      className="Modal form-modal"
      overlayClassName="Overlay"
      onAfterOpen={props.trackEvent('opened contact form')}
      isOpen={props.contactModalOpen}
      onRequestClose={props.closeContactModal}
      >
      <iframe
        title='contact-form'
        id='contact-form'
        src="https://docs.google.com/forms/d/e/1FAIpQLSdVQ4W3C02Dvcr3UE1uU_2c6tJPcmVncgE8pJNAfExQkU-o3Q/viewform?embedded=true"
        width="100%" height={props.height-40} frameBorder="0" marginHeight="0" marginWidth="0"
        >Loading…</iframe>
      <button className="close-button my-close-button"  onClick={props.closeContactModal} aria-label="Close modal" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </Modal>
  )
};
