import React, {useState} from 'react';
import ReactGA from 'react-ga';
import 'foundation-sites/dist/css/foundation.min.css';
import './App.scss';
import MainPage from './components/main_page.js'
// import ContactsPage from './components/contact_page.js'
import ContactModal from './components/contact_modal.js'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export default function App() {
  const [height, setHeight] = useState(window.innerHeight);
  const [contactModalOpen, setContactModalOpen]        = useState(false);
  function closeContactModal(){
    setContactModalOpen(false)
  }
function trackEvent(s) {

  return;
}
  return (
    <Router>
      <div id="app" className="app">
       <Switch>
          <Route
            exact path="/"
            render={(props) =>
              <MainPage {...props}
                trackEvent={trackEvent}
                height={height}
                contactModalOpen={contactModalOpen}
                setContactModalOpen={setContactModalOpen}
                closeContactModal={closeContactModal}
              />
            }
          />
       </Switch>
      <ContactModal
        trackEvent={trackEvent}
        closeContactModal={closeContactModal}
        contactModalOpen={contactModalOpen}
        height={height}
        />
      </div>
    </Router>


  );
}
          // <Route path="/contact" exact component={ContactsPage}/>
