
import React from 'react';
import Footer from '../footer/footer'
import './testimonial.scss'



export default function Testimonial(props) {



    return (
        <section id="testimonial" className='text-center visible pad-top-50 relative'>
            <div className="container">
                <div className="grid-container">
                    <div className="grid-x align-center align-middle height60">
                        <div className="small-12 cell">
                            <div className="grid-x align-center">
                                <div className="small-12 medium-10 large-6 cell">
                                    <h2 className="h1 mb-50">What past Trailblazer Scholars say</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid-x align-center align-middle height60 mb-80">
                <div className="small-10 cell">
                    <div className="grid-x align-center">
                        <div className="small-10 medium-7 cell">
                            <div className="photo-wrap">
                                <img className="round mar-bot-20" src="images/testimonial/kenya.jpg" />
                            </div>
                            <p><span className="italic"> “Attending a cert prep course helped me study for the Salesforce Admin exam and the exam fee waiver allowed me to focus on studying instead of worrying about how I would pay for it. Without Trailblazer Scholarship, I wouldn't have landed a job as quickly as I did.” </span><strong><a href="https://trailblazer.me/id/kenyashakir" target="_blank"> Kenya Shakir </a></strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-x align-center align-middle height60 mb-80">
                <div className="small-10 cell">
                    <div className="grid-x align-center">
                        <div className="small-10 medium-7 cell">
                            <div className="photo-wrap">
                                <img className="round mar-bot-20" src="images/testimonial/mike.png" />
                            </div>
                            <p><span className="italic"> “Trailblazer Scholarship is great for anyone looking to advance their career in the Salesforce ecosystem. I got the foundational support needed to pass my Salesforce Certified Administrator exam and I received mentorship from Salesforce employees and tips for my job search.” </span><strong><a href="https://trailblazer.me/id/mikechichester" target="_blank"> Mike Chichester</a></strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-x align-center align-middle height60 mb-80">
                <div className="small-10 cell">
                    <div className="grid-x align-center">
                        <div className="small-10 medium-7 cell">
                            <div className="photo-wrap">
                                <img className="round mar-bot-20" src="images/testimonial/hermela.jpeg" />
                            </div>
                            <p><span className="italic"> “Trailblazer Scholarship far exceeded my expectations. I received training and one-on-one mentorship to help me earn the Salesforce Platform App Builder certification on the first attempt, career development workshops, and networking opportunities with other Salesforce professionals.” </span><strong><a href="https://trailblazer.me/id/hermela" target="_blank"> Hermela Felten</a></strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-x align-center align-middle height60 mb-80">
                <div className="small-10 cell">
                    <div className="grid-x align-center">
                        <div className="small-10 medium-7 cell">
                            <div className="photo-wrap">
                                <img className="round mar-bot-20" src="images/testimonial/derina.jpg" />
                            </div>
                            <p><span className="italic"> "Trailblazer Scholarship was a step toward achieving yet another certification to validate my Trailhead learning journey and fortify my role in the Salesforce ecosystem.” </span><strong><a href="https://trailblazer.me/id/dnunes" target="_blank"> Derina Nunes</a></strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer">&nbsp;</div>
            <Footer />
            <div className="bottom-message mt-120">
                <div className="footer-info mb-20">For more information or any questions, please contact <a href="mailto:trailblazerconnect@salesforce.com">trailblazerconnect@salesforce.com</a>.</div>
                <h3 className="">Ready to blaze <span className="script blue-light">your</span> trail?</h3>
                <div className="bottom-disclaimer">
                    {/* <strong>DISCLAIMER:</strong> No purchase necessary. Void where prohibited. Contest runs from September 13, 2022 9:00 a.m. PT and ends on November 30, 2022 at 11:59 p.m. PT.
Open to legal residents of the U.S. (incl. D.C.). Must be 18+.  */}
                    <p>*See local Official Rules for specific closing dates and timezones below.</p>
                    <span><a href="https://org62.my.salesforce.com/sfc/p/000000000062/a/3y000001kVLk/TGlkT.tgO41vc91oBNwzcV9ArUxLJaBXTPw6ldtdZYo">Official Rules USA</a>&nbsp;&nbsp;</span>
                    <span><a href="https://org62.my.salesforce.com/sfc/p/000000000062/a/3y000001kVLp/d_L7gAu7DEdpkXayRjNAiuCw0BJxchpJQZDPvy5QwZ4">Official Rules ANZ</a>&nbsp;&nbsp;</span>
                    <span><a href="https://org62.my.salesforce.com/sfc/p/000000000062/a/3y000001kVLu/d_fv6TfC4V2.GHGEu1yGSxlDMBzUbhR5BVB7gU6kvwg">Official Rules South Africa</a>&nbsp;&nbsp;</span>
                    <br />
                        <br/>
                    <div className="page-footer_content">
                        <div className="page-footer_legal">
                            <p>© Copyright 2022 Salesforce.com, inc.&nbsp;<a href="https://www.salesforce.com/company/legal/intellectual" adhocenable="false">All rights reserved</a>. Various trademarks held by their respective owners. Salesforce Canada Corporation. 10 Bay Street, Suite 400&nbsp;Toronto, ON, M5J 2R8, Canada</p>
                        </div>
                        <nav className="page-footer_links">
                            <ul className="page-footer_links_list">
                                <li className="page-footer_links_item">

                                    <a className="page-footer_link " href="https://www.salesforce.com/company/legal/">Legal</a>
                                </li>
                                <li className="page-footer_links_item">

                                    <a className="page-footer_link " href="https://www.salesforce.com/company/legal/sfdc-website-terms-of-service.jsp">Terms of Service</a>
                                </li>
                                <li className="page-footer_links_item">

                                    <a className="page-footer_link " href="https://www.salesforce.com/ca/company/privacy/">Privacy</a>
                                </li>
                                <li className="page-footer_links_item">

                                    <a className="page-footer_link " href="https://www.salesforce.com/company/disclosure/">Responsible Disclosure</a>
                                </li>
                                <li className="page-footer_links_item">

                                    <a className="page-footer_link " href="https://trust.salesforce.com/" target="_blank">Trust</a>
                                </li>
                                <li className="page-footer_links_item">

                                    <a className="page-footer_link " href="https://www.salesforce.com/ca/form/contact/contactme.jsp?d=7010M000001wyRQ" target="_blank">Contact</a>
                                </li>
                                {/* <li className="page-footer_links_item">

                                    <a className="page-footer_link optanon-toggle-display" href="#" data-ignore-geolocation="true">Cookie Preferences</a>
                                </li> */}

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section >
    )
};
