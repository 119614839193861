import React from 'react';
import { useSpring, animated } from 'react-spring';
import butterfly1, { ReactComponent as Butterfly1 } from './butterfly1.svg';
import butterfly2, { ReactComponent as Butterfly2 } from './butterfly2.svg';
import './footer.scss';
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 30 * -1}px,${y / 50}px,0)`;
export default function Footer(props) {
  const [settings, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 50, tension: 550, friction: 140 } }));
  return (
    <>

      <footer className="footer" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
        <div className="grid-x align-center">

        </div>
        <div className="butterfly-group">
          <animated.div className="butterfly1" style={{ transform: settings.xy.interpolate(trans2) }}><Butterfly1 /></animated.div>
          <animated.div className="butterfly2" style={{ transform: settings.xy.interpolate(trans3) }}><Butterfly2 /></animated.div>
        </div>

      </footer>
      <div className="bg-footer"></div>

    </>
  )
};
