import React from 'react';
import './why.scss'


export default function Why(props) {


    return (
        <section id="why" className='text-center visible'>
            <div className="container">
                <div className="grid-container">
                    <div className="grid-x align-center align-middle height60">
                        <div className="small-12 cell">
                            <div className="small-12 medium-8 large-6 cell mb-30 mt-30">
                                <h2>Blaze A Trail to New Career Opportunities</h2>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x grid-padding-y align-center">
                            <div className="small-12 medium-4 large-4 cell">
                                <img className="card__img" src="images/description/book.png" />
                                <h3 className="card__header">Learn in-demand skills</h3>
                                <div className="card__body">
                                  Salesforce expertise has never been more important. Learn industry-leading skills directly from Salesforce experts.
                                </div>
                            </div>
                            <div className="small-12 medium-4 large-4 cell">
                                <img className="card__img" src="images/description/resume.png" />
                                <h3 className="card__header">Accelerate your career advancement</h3>
                                <div className="card__body">
                                  Validate your skills and gain global recognition as a Salesforce expert with Salesforce credentials. Get a competitive edge that leads to new opportunities.
                                </div>
                            </div>
                            <div className="small-12 medium-4 large-4 cell">
                                <img className="card__img" src="images/description/world_love.png" />
                                <h3 className="card__header"> Connect to opportunities</h3>
                                <div className="card__body">
                                    Join the Trailblazer Community for invaluable networking,
                                    mentorship, and career opportunities.
                                </div>
                            </div>
                            {/* <p className='pad-top-50'>For more information or any questions, please contact <a href="mailto:trailblazerconnect@salesforce.com">trailblazerconnect@salesforce.com.</a>
                                <br /><strong>Happy Trails and best of luck!</strong>
                            </p> */}
                        </div>

                        {/* <div className="btn-wrap pad-top-20">
                            <a onClick={() => props.setContactModalOpen(true)} id={props.id} target="_blank" type="button" rel="noopener noreferrer" className="button large btn-blue full">Apply Now</a>
                        </div> */}

                    </div>
                </div>
            </div>

        </section >
    )
};
