import React, { useRef } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";


export default function Nav(props) {



  const mobMenu = useRef(null);

  function openContactModal() {
    props.setContactModalOpen(true)
    mobMenu.current.classList.remove('open');

  }
  function toggleMenu() {
    mobMenu.current.classList.toggle('open');
  }
  return (
    <header className="header">
      <div></div>
      <div className="grid-container full">
        <div className="grid-x">
          <div className="cell">

            <div className="main-navigation-top relative">
              <div className="grid-container">
                <div className="grid-x grid-padding-x grid-padding-y align-middle">


                  {/* MOBILE LOGO */}
                  <div className="cell small-3 ">
                    <div className="logo-header">
                      <a href="/">
                        <img src="/images/logo.png" alt="Salesforce" />
                      </a>
                    </div>
                  </div>
                  {/* /////////// */}

                  <div className="cell small-9 text-right show-for-medium">
                    <div className="desktop-menu">
                      {/* <img className="top-logo show-for-large" src="images/logo.png" /> */}
                      <nav className="main-menu">
                        <ul className="menu align-right mt-5">
                          <li>
                            <Link to="hero"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                            >Home
                            </Link>
                          </li>
                          <li>
                            <Link to="description"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                            >About
                            </Link>
                          </li>
                          <li>
                            <Link to="eligibility"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                            >Eligibility
                            </Link>
                          </li>
                          <li>
                            <Link to="why"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                            >Be A Trailblazer
                            </Link>
                          </li>
                          <li>
                            <Link to="testimonial"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                            >Testimonials
                            </Link>
                          </li>
                          {/* <li><a onClick={() => props.setContactModalOpen(true)} >Apply Now</a></li> */}
                          {/* <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSdVQ4W3C02Dvcr3UE1uU_2c6tJPcmVncgE8pJNAfExQkU-o3Q/viewform?usp=sf_link" target="_blank" >Apply Now</a></li> */}
                        </ul>
                      </nav>
                    </div>
                    </div>
                    <div className="cell small-9 text-right hide-for-medium burger-cell">
                    <div className="menu-button burger-button" aria-hidden="true">
                      <a href="#" className="open-menu" onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-navigation">
              <div className="grid-container">
                <div className="grid-x grid-padding-x">
                  <div className="cell">

                    <div ref={mobMenu} className="mobile-menu" id="mobile-menu" aria-hidden="true">
                      <div className="menu-button" aria-hidden="true" onClick={toggleMenu}>
                        <a href="#" className="open-menu">
                          <span></span>
                          <span></span>
                          <span></span>
                        </a>
                      </div>
                      <nav className="overlay-menu" role="navigation">
                        <ul className="main-menu align-center">
                        <li>
                            <Link to="hero"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                              onClick={toggleMenu}
                            >Home
                            </Link>
                          </li>
                          <li>
                            <Link to="description"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                              onClick={toggleMenu}
                            >About
                            </Link>
                          </li>
                          <li>
                            <Link to="eligibility"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                              onClick={toggleMenu}
                            >Eligibility
                            </Link>
                          </li>
                          <li>
                            <Link to="why"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                              onClick={toggleMenu}
                            >Be A Trailblazer
                            </Link>
                          </li>
                          <li>
                            <Link to="testimonial"
                              activeClass="active"
                              spy={true}
                              smooth={true}
                              offset={-80}
                              duration={500}
                              onClick={toggleMenu}
                            >Testimonials
                            </Link>
                          </li>
                          {/* <li><a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdVQ4W3C02Dvcr3UE1uU_2c6tJPcmVncgE8pJNAfExQkU-o3Q/viewform?usp=sf_link">Apply Now</a></li> */}
                          {/* <li><a onClick={openContactModal} >Apply Now</a></li> */}
                        </ul>
                      </nav>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </header>
  )
};
