import React from 'react';
import './description.scss'
import bird4, { ReactComponent as Bird } from './bird4.svg';
import { useSpring, animated } from 'react-spring'
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`


export default function Description(props) {


    const [settings, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 50, tension: 550, friction: 140 } }))
    return (
        <section id="description" className='text-center visible'>
            <div className="container" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
                <div className="grid-container">
                    <div className="grid-x align-center align-middle height60 pad-bottom-50">
                        <div className="small-12 cell">
                            <animated.div style={{ transform: settings.xy.interpolate(trans1) }}><Bird /></animated.div>
                            <div className="grid-x align-center">
                                <div className="small-12 medium-8 large-6 cell">
                                    <h1 className="h1 mb-50">Accelerate Your Career Journey with Salesforce</h1>
                                </div>
                            </div>
                        </div>

                        <div className="small-12 medium-10 cell">
                            <p>At Salesforce, we’re committed to empowering anyone to learn new skills and advance their careers.</p>
                            <p>The 2023 Trailblazer Scholarship provides hands-on learning and expert-led guidance to help you get Salesforce-certified and land a job.</p>
                        </div>
                    </div>
                </div>
            </div>

        </section >
    )
};
